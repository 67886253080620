<template>
  <el-dialog
    :visible.sync="dialogVisible"
    top="1%"
    width="90%"
    :center="true"
    title="试卷"
    :before-close="doClose"
  >
    <div style="height:650px">
      <section class="CourseTablekponit">
    <div class="operationControl" style="border:0">
      <div class="searchbox" >
        <div title="试卷名称" class="searchboxItem">
          <span  class="itemLabel">试卷名称:</span>
          <el-input clearable v-model="paperName" type="text" size="small" placeholder="请输入试卷名称" />
        </div>
        <div>
          <el-button class="bgc-bv" @click="getData()">查询</el-button>
        </div>
      </div>
    </div>
    <div class="framePage-scroll">
      <div class="ovy-a">
        <el-table
          ref="multipleTable"
          :data="tableData"
          :height="tableHeight"
          size="small"
          tooltip-effect="dark"
          style="width: 100%"
          stripe
          border
        >
          <el-table-column label width="35px" align="center">
            <template slot-scope="scope">
              <el-radio v-model="radio" :label="scope.row" />
            </template>
          </el-table-column>
          <el-table-column label="试卷名称" align="center" show-overflow-tooltip prop="paperName" />
          <el-table-column label="试卷来源" align="center" show-overflow-tooltip prop="paperOrgin">
              <template slot-scope="scope">
                  {{scope.row.paperOrgin == '10'?'上传':'组卷'}}
              </template>
          </el-table-column>
          <el-table-column label="创建日期" align="center" show-overflow-tooltip prop="createTime">
              <template slot-scope="scope">
                  {{scope.row.createTime | momentDate}}
              </template>
          </el-table-column>
          <Empty slot="empty" />
        </el-table>
      </div>
    </div>
    <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
    <span class="dialog-footer" style="padding:15px">
      <div>
        <el-button class="bgc-bv" @click="dialogClose">取消</el-button>
        <el-button class="bgc-bv" @click="doOk">确定</el-button>
      </div>
    </span>
  </section>
    </div>
  </el-dialog>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import tree from "@/components/treePopup";
import { mapGetters } from "vuex";
export default {
  name: "courseTable",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
        dialogVisible:false,
      paperName: "",
      radio: "",
    };
  },
  computed: {
   
  },
  created() { },
  methods: {
      showPopUp() {
        this.dialogVisible = true
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        paperName: this.paperName 
      };
      this.radio = "";
      this.doFetch({
        url: "/evaluate/paper/choiceList",
        params,
        pageNum
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight = 740 - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 ) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    doOk() {
      const { radio } = this;
      if (radio) {
        this.$emit("eventBus", radio);
        this.dialogClose()
      } else {
        this.$message({
          message: "请选择试卷",
          type: "warning"
        });
      }
    },
    dialogClose() {
      this.dialogVisible = false
      // this.params = {};
    },
    
  },
  watch: {
    compOwnerId: function(val) {
      if (val == "") {
        this.CompanyList = [];
      }
    }
  },
};
</script>
<style lang="less" scoped>
.CourseTablekponit {
  .el-radio {
    .el-radio__label {
      display: none;
    }
    .el-radio__inner{
      width: 18px;
      height: 18px;
      border-color: #777;
    }
    .el-radio__inner:hover{
      border-color: #5c6be8;
    }
    .el-radio__input.is-checked .el-radio__inner{
      border-color: #5c6be8;
      background-color: #5c6be8;
    }
  }
}
</style>