<template>
  <div id="testInfo" class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">鉴定机构管理</a>
          <i>></i>
          <a href="javascript:;" @click="doClose">考务安排</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">新建考试</a>
        </span>
      </div>

      <div class="framePage-body">
        <el-steps
          :active="active"
          finish-status="success"
          simple
          style="margin-top: 20px"
        >
          <el-step
            :title="
              '第一步:' + (examinationStu == 'add') ? '创建考试' : '考试4详情'
            "
          ></el-step>
          <el-step
            :title="'第二步:' + (examinationStu == 'add') ? '组卷' : '组卷详情'"
          ></el-step>
          <el-step
            :title="
              '第三步:' + (examinationStu == 'add')
                ? '创建考试配置'
                : '考试配置详情'
            "
          ></el-step>
        </el-steps>
        <div class="framePage-scroll" v-if="active == 0">
          <div class="ovy-a">
            <el-form
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm"
              label-width="120px"
              :disabled="isPublish"
            >
              <el-form-item label="考试名称：" prop="examName">
                <el-input
                  v-model="ruleForm.examName"
                  placeholder="请输入考试名称"
                  maxlength="100"
                  size="small"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="考试封面："
                prop="examCoverUrl"
                class="form-item"
              >
                <el-upload
                  :on-change="handleAvatarSuccess2"
                  :before-upload="beforeAvatarUpload2"
                  :http-request="$requestMine"
                  :show-file-list="false"
                  class="img-el-upload"
                  action
                  accept="image/png, image/gif, image/jpg, image/jpeg"
                >
                  <el-image
                    :src="
                      ruleForm.examCoverUrl || require('@/assets/develop.png')
                    "
                    fit="contain"
                    class="imgCenter"
                  ></el-image>
                </el-upload>
              </el-form-item>
              <el-form-item label="考试时间：" prop="theoryTestTime">
                <el-date-picker
                  size="small"
                  v-model="ruleForm.theoryTestTime"
                  type="datetimerange"
                  range-separator="至"
                  start-placeholder="考试开始日期"
                  end-placeholder="考试结束日期"
                  format="yyyy-MM-dd HH:mm"
                  value-format="yyyy-MM-dd HH:mm"
                >
                </el-date-picker>
                  <!-- :picker-options="datePickerOptions" -->
              </el-form-item>
              <el-form-item label="考试地点：" prop="theoryTestLocation">
                <el-input
                  v-model="ruleForm.theoryTestLocation"
                  placeholder="请输入考试地点"
                  maxlength="100"
                  size="small"
                ></el-input>
              </el-form-item>
              <el-form-item label="组卷方式：" prop="volumeGroupMethod">
                <el-radio-group
                  v-model="ruleForm.volumeGroupMethod"
                  size="small"
                >
                  <el-radio label="10" border>选择试卷</el-radio>
                  <el-radio label="20" border>随机组卷</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="考试注意事项：" prop="examNotes">
                <el-input
                  v-model="ruleForm.examNotes"
                  type="textarea"
                  placeholder="请输入考试注意事项"
                  size="small"
                ></el-input>
              </el-form-item>
            </el-form>
            <div class="flexcc">
              <!-- <el-button @click="doClose()">取 消</el-button> -->
              <el-button class="bgc-bv" type="primary" @click="doOk"
                >下一步</el-button
              >
            </div>
          </div>
        </div>
        <!-- 组卷 -->
        <div
          class="framePage-halfBody"
          v-else-if="active == 1 && this.ruleForm.volumeGroupMethod == '20'"
        >
          <div class="framePage-article">
            <div class="ovy-a">
              <div>
                <div class="searchBox">
                  <el-form
                    :model="questionBankForm"
                    :rules="questionBankFormRules"
                    ref="questionBankForm"
                    label-width="100px"
                    :disabled="isPublish"
                  >
                    <el-form-item label="组卷名称：" prop="paperName">
                      <el-input
                        v-model="questionBankForm.paperName"
                        placeholder="请输入组卷名称"
                        size="small"
                      ></el-input>
                    </el-form-item>
                    <el-form-item label="题库名称：" prop="questionBankId">
                      <el-select
                        v-model="questionBankForm.questionBankId"
                        placeholder="请选择题库"
                        @change="questionbankChange"
                        filterable
                        remote
                        size="small"
                        :remote-method="getquestionBank"
                      >
                        <el-option
                          :label="item.questionBankName"
                          :value="item.questionBankId"
                          v-for="(item, index) in questionbankList"
                          :key="index"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item prop="paperObj" class="paperObj">
                      <div>
                        <p>
                          在
                          <span
                            style="
                              display: inline-block;
                              width: 50px;
                              text-align: center;
                            "
                            >{{ choiceNum }}</span
                          >道单选题中随机抽
                          <el-input-number
                            v-model="questionBankForm.paperObj.SinglechoiceNum"
                            size="small"
                            :precision="0"
                            :min="0"
                            :max="choiceNumMax"
                            :disabled="!questionBankForm.questionBankId"
                            style="width: 140px; margin-right: 10px"
                          ></el-input-number
                          >道题目，每题
                          <el-input-number
                            v-model="
                              questionBankForm.paperObj.SinglechoiceScore
                            "
                            size="small"
                            :min="0"
                            :disabled="!questionBankForm.questionBankId"
                            style="width: 140px; margin-right: 10px"
                          ></el-input-number
                          >分
                        </p>
                        <p>
                          在
                          <span
                            style="
                              display: inline-block;
                              width: 50px;
                              text-align: center;
                            "
                          >
                            {{ multiselectNum }} </span
                          >道多选题中随机抽
                          <el-input-number
                            v-model="
                              questionBankForm.paperObj.MultiplechoiceNum
                            "
                            size="small"
                            :min="0"
                            :precision="0"
                            :max="multiselectNumMax"
                            :disabled="!questionBankForm.questionBankId"
                            style="width: 140px; margin-right: 10px"
                          ></el-input-number
                          >道题目，每题
                          <el-input-number
                            v-model="
                              questionBankForm.paperObj.MultiplechoiceScore
                            "
                            size="small"
                            :min="0"
                            :disabled="!questionBankForm.questionBankId"
                            style="width: 140px; margin-right: 10px"
                          ></el-input-number
                          >分
                        </p>
                        <p>
                          在
                          <span
                            style="
                              display: inline-block;
                              width: 50px;
                              text-align: center;
                            "
                          >
                            {{ judgeNum }} </span
                          >道判断题中随机抽
                          <el-input-number
                            v-model="questionBankForm.paperObj.judgeNum"
                            size="small"
                            :min="0"
                            :precision="0"
                            :max="judgeNumMax"
                            :disabled="!questionBankForm.questionBankId"
                            style="width: 140px; margin-right: 10px"
                          ></el-input-number
                          >道题目，每题
                          <el-input-number
                            v-model="questionBankForm.paperObj.judgeScore"
                            size="small"
                            :min="0"
                            :disabled="!questionBankForm.questionBankId"
                            style="width: 140px; margin-right: 10px"
                          ></el-input-number
                          >分
                        </p>
                      </div>
                    </el-form-item>
                    <div
                      style="
                        display: flex;
                        padding-bottom: 15px;
                        text-align: center;
                      "
                    >
                      <p>总题数:{{ questionBankForm.totalNum }}道</p>
                      <p style="margin-left: 20px">
                        总分为:{{ questionBankForm.totalScore }}分
                      </p>
                    </div>
                    <el-form-item label="及格分数:" prop="paperScore">
                      <div class="">
                        <el-input-number
                          size="small"
                          v-model="questionBankForm.paperScore"
                          :min="0"
                          :precision="0"
                          style="width: 200px; margin-right: 10px"
                        ></el-input-number>
                        <span>分</span>
                      </div>
                    </el-form-item>
                  </el-form>
                </div>
                <div class="flexcc" style="padding: 0 0" v-if="!isPublish">
                  <el-button
                    class="bgc-bv"
                    type="primary"
                    @click="doTestComposition()"
                    size="small"
                    >生成试卷</el-button
                  >
                  <!-- <el-button
                    class="bgc-bv"
                    type="primary"
                    :disabled="lookDisabled"
                    @click="lookTest()"
                    size="small"
                    >预览试卷</el-button
                  > -->
                </div>
              </div>
            </div>
          </div>
          <div class="framePage-body">
            <div style="display: flex; justify-content: flex-end;margin:10px;">
              <el-button
                class="bgc-bv"
                type="primary"
                v-if="listData.length > 0"
                @click="doActive()"
                size="small"
                >下一步</el-button
              >
            </div>
            <div class="ovy-a">
              <div
                class="framePage-scroll flexdc"
                :style="!listData.length ? 'height:93%' : ''"
              >
                <div class="ovy-a flex1" id="list-box">
                  <div
                    class="list"
                    v-for="(item, index) in listData"
                    :key="index"
                    v-show="listData.length > 0"
                  >
                    <div class="list-item df">
                      <span
                        class="tixing"
                        :class="{
                          tixing1: item.questionType == 10,
                          tixing2: item.questionType == 20,
                          tixing3: item.questionType == 30,
                          tixing4: item.questionType == 40,
                        }"
                        >{{
                          item.questionType == "10"
                            ? "单选题"
                            : item.questionType == "20"
                            ? "多选题"
                            : item.questionType == "30"
                            ? "判断题"
                            : "填空题"
                        }}</span
                      >
                      <div class="subject flex1">
                        <div class="subject-title">
                          <span
                            v-html="$xss(item.questionTitle)"
                            style="display: flex"
                            >{{ item.questionTitle }}</span
                          >
                        </div>
                        <div class="option" v-if="item.questionType == 10">
                          <span
                            v-for="(items, indexs) in item.optionList"
                            :key="indexs"
                            >{{ letterArr[indexs] }}.{{ items.optionContent }}
                            <el-image
                              v-if="items.optionSpecialContent"
                              class="qbimg"
                              :src="
                                items.optionSpecialContent ||
                                require('@/assets/develop.png')
                              "
                              fit="contain"
                            ></el-image>
                          </span>
                        </div>
                        <div class="option" v-if="item.questionType == 20">
                          <span
                            v-for="(items, indexs) in item.optionList"
                            :key="indexs"
                            >{{ letterArr[indexs] }}.{{ items.optionContent }}
                            <el-image
                              v-if="items.optionSpecialContent"
                              class="qbimg"
                              :src="
                                items.optionSpecialContent ||
                                require('@/assets/develop.png')
                              "
                              fit="contain"
                            ></el-image
                          ></span>
                        </div>
                        <div class="analysis">
                          正确答案：<span
                            v-show="item.optionList.length != 0"
                            v-for="(items, indexs) in item.optionList"
                            :key="indexs"
                            >{{
                              items.optionIsCorrect ? letterArr[indexs] : ""
                            }}</span
                          >
                          <span v-show="item.optionList.length == 0">{{
                            item.questionAnswer
                          }}</span>
                        </div>
                        <div style="display: flex">
                          <p>答案解析：</p>
                          <span
                            class="analysis img-boxs"
                            style="flex: 1"
                            v-html="$xss(item.questionAnalysis)"
                            >{{ item.questionAnalysis }}</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <Empty2 slot="empty"  v-show="!listData.length"/> -->
              </div>
            </div>
            <div class="pageNum">
              <el-pagination
                :total="fenYe.total"
                :page-size="fenYe.size"
                :current-page="fenYe.currentPage"
                background
                :page-sizes="[10, 20, 30, 40, 50]"
                layout="total, sizes,prev, pager, next,jumper"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
              ></el-pagination>
            </div>
            <!-- <PageNum
              :apiData="apiData"
              @sizeChange="sizeChange"
              @getData="getData"
            /> -->
          </div>
        </div>
        <!-- 试卷组卷 -->
        <div
          class="framePage-halfBody"
          v-else-if="active == 1 && this.ruleForm.volumeGroupMethod == '10'"
        >
          <div class="framePage-article">
            <div class="ovy-a">
              <div>
                <div class="searchBox">
                  <div style="display: flex; justify-content: space-between">
                    <span>选择已有试卷</span>
                    <el-button
                      class="bgc-bv"
                      type="primary"
                      :disabled="isPublish"
                      @click="doTestHas()"
                      size="small"
                      >选择试卷</el-button
                    >
                  </div>
                  <el-form
                    :model="TestHasForm"
                    :rules="TestHasFormRules"
                    ref="questionBankForm"
                    label-width="100px"
                  >
                    <div style="padding-bottom: 15px">
                      <p>试卷名称:{{ TestHasForm.paperName }}</p>
                      <p>总题数:{{ TestHasForm.totalNum }}道</p>
                      <p>总分为:{{ TestHasForm.totalScore }}分</p>
                      <p>及格分数:{{ TestHasForm.paperScore }}分</p>
                    </div>
                  </el-form>
                </div>
              </div>
            </div>
          </div>
          <div class="framePage-body">
            <div style="display: flex; justify-content: flex-end;margin:10px">
              <el-button
                class="bgc-bv"
                type="primary"
                v-if="listData.length > 0"
                @click="doActiveBind()"
                size="small"
                >下一步</el-button
              >
            </div>
            <div class="ovy-a">
              <div
                class="framePage-scroll flexdc"
                :style="!listData.length ? 'height:93%' : ''"
              >
                <div class="ovy-a flex1" id="list-box">
                  <div
                    class="list"
                    v-for="(item, index) in listData"
                    :key="index"
                    v-show="listData.length > 0"
                  >
                    <div class="list-item df">
                      <span
                        class="tixing"
                        :class="{
                          tixing1: item.questionType == 10,
                          tixing2: item.questionType == 20,
                          tixing3: item.questionType == 30,
                          tixing4: item.questionType == 40,
                        }"
                        >{{
                          item.questionType == "10"
                            ? "单选题"
                            : item.questionType == "20"
                            ? "多选题"
                            : item.questionType == "30"
                            ? "判断题"
                            : "填空题"
                        }}</span
                      >
                      <div class="subject flex1">
                        <div class="subject-title">
                          <span
                            v-html="$xss(item.questionTitle)"
                            style="display: flex"
                            >{{ item.questionTitle }}</span
                          >
                        </div>
                        <div class="option" v-if="item.questionType == 10">
                          <span
                            v-for="(items, indexs) in item.optionList"
                            :key="indexs"
                            >{{ letterArr[indexs] }}.{{ items.optionContent }}
                            <el-image
                              v-if="items.optionSpecialContent"
                              class="qbimg"
                              :src="
                                items.optionSpecialContent ||
                                require('@/assets/develop.png')
                              "
                              fit="contain"
                            ></el-image>
                          </span>
                        </div>
                        <div class="option" v-if="item.questionType == 20">
                          <span
                            v-for="(items, indexs) in item.optionList"
                            :key="indexs"
                            >{{ letterArr[indexs] }}.{{ items.optionContent }}
                            <el-image
                              v-if="items.optionSpecialContent"
                              class="qbimg"
                              :src="
                                items.optionSpecialContent ||
                                require('@/assets/develop.png')
                              "
                              fit="contain"
                            ></el-image
                          ></span>
                        </div>
                        <div class="analysis">
                          正确答案：<span
                            v-show="item.optionList.length != 0"
                            v-for="(items, indexs) in item.optionList"
                            :key="indexs"
                            >{{
                              items.optionIsCorrect ? letterArr[indexs] : ""
                            }}</span
                          >
                          <span v-show="item.optionList.length == 0">{{
                            item.questionAnswer
                          }}</span>
                        </div>
                        <div style="display: flex">
                          <p>答案解析：</p>
                          <span
                            class="analysis img-boxs"
                            style="flex: 1"
                            v-html="$xss(item.questionAnalysis)"
                            >{{ item.questionAnalysis }}</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <Empty2 slot="empty"  v-show="!listData.length"/> -->
              </div>
            </div>
            <div class="pageNum">
              <el-pagination
                :total="fenYe.total"
                :page-size="fenYe.size"
                :current-page="fenYe.currentPage"
                background
                :page-sizes="[10, 20, 30, 40, 50]"
                layout="total, sizes,prev, pager, next,jumper"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
              ></el-pagination>
            </div>
            <!-- <PageNum
              :apiData="apiData"
              @sizeChange="sizeChange"
              @getData="getData"
            /> -->
          </div>
        </div>
        <!-- 第三部 -->
        <div class="framePage-scroll" v-if="active == 2">
          <div class="ovy-a">
            <el-form
              :model="configureRuleForm"
              :rules="configureRule"
              ref="configureRuleForm"
              label-width="190px"
              :disabled="isPublish"
            >
             
              <el-form-item label="启用考试迟到限制：" prop="examLatenessLimit">
                <el-radio-group
                  v-model="configureRuleForm.examLatenessLimit"
                  size="small"
                >
                  <el-radio :label="true">启用</el-radio>
                  <el-radio :label="false">停用</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item
                label="学员迟到超"
                prop="limitTime"
                v-if="configureRuleForm.examLatenessLimit"
              >
                <el-input
                  placeholder="请输入"
                  v-model="configureRuleForm.limitTime"
                >
                  <template slot="append">分钟后不允许进入考试</template>
                </el-input>
                <!-- <el-radio-group
                  v-model="configureRuleForm.examLatenessLimit"
                  size="small"
                >
                 <el-radio :label="true" >启用</el-radio>
                  <el-radio :label="false" >停用</el-radio>
                </el-radio-group> -->
              </el-form-item>
              <el-form-item label="考试结束后是否显示分数：" prop="isShowScore">
                <el-radio-group
                  v-model="configureRuleForm.isShowScore"
                  size="small"
                >
                  <el-radio :label="true">显示</el-radio>
                  <el-radio :label="false">不显示</el-radio>
                </el-radio-group>
                <p>注意:仅限客观题</p>
              </el-form-item>
              <el-form-item
                label="PC端主摄像头录像监考："
                prop="mainCameraProctoring"
              >
                <el-radio-group
                  v-model="configureRuleForm.mainCameraProctoring"
                  size="small"
                >
                  <el-radio :label="true">启用</el-radio>
                  <el-radio :label="false">停用</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item
                label="考试开始后每："
                prop="photoComparisonFrequency"
                v-if="configureRuleForm.mainCameraProctoring"
              >
                <el-input
                  placeholder="请输入"
                  v-model="configureRuleForm.photoComparisonFrequency"
                >
                  <template slot="append">分钟抓拍一张照片进行比对</template>
                </el-input>
              </el-form-item>
              <el-form-item label="PC端桌面录屏监考：" prop="desktopProctoring">
                <el-radio-group
                  v-model="configureRuleForm.desktopProctoring"
                  size="small"
                >
                  <el-radio :label="true">启用</el-radio>
                  <el-radio :label="false">停用</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item
                label="手机端副摄像头录像监考："
                prop="subCameraProctoring"
              >
                <el-radio-group
                  v-model="configureRuleForm.subCameraProctoring"
                  size="small"
                >
                  <el-radio :label="true">启用</el-radio>
                  <el-radio :label="false">停用</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item
                label="切屏后强制交卷："
                prop="cutScreenHandInPaper"
              >
                <el-radio-group
                  v-model="configureRuleForm.cutScreenHandInPaper"
                  size="small"
                >
                  <el-radio :label="true">启用</el-radio>
                  <el-radio :label="false">停用</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item
                label="学员切换页面超"
                prop="cutScreenFrequency"
                v-if="configureRuleForm.cutScreenHandInPaper"
              >
                <el-input
                  placeholder="请输入"
                  v-model="configureRuleForm.cutScreenFrequency"
                >
                  <template slot="append">次后强制交卷</template>
                </el-input>
              </el-form-item>
              <el-form-item
                label="切换到其他页面"
                prop="cutScreenTime"
                v-if="configureRuleForm.cutScreenHandInPaper"
              >
                <el-input
                  placeholder="请输入"
                  v-model="configureRuleForm.cutScreenTime"
                >
                  <template slot="append">秒即判断为切屏</template>
                </el-input>
              </el-form-item>
              <el-form-item>
                <div class="flexcc">
                  <!-- <el-button @click="doClose()">取 消</el-button> -->
                  <el-button class="bgc-bv" type="primary" @click="doOkThree"
                    >创建考试</el-button
                  >
                </div>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
    <examPaper ref="examPaper" @eventBus="examPaperBack" />
  </div>
</template>
<script>
import examPaper from "@/views/appraisalInstitution/examinationArrangement/examPaper";
export default {
  name: "questionbankInformation",
  components: {
    examPaper,
  },
  data() {
    //校验题目分值
    let checkPaperObj = (rule, value, callback) => {
      let reg = /^[0-9]\d*$/;
      let {
        SinglechoiceNum,
        SinglechoiceScore,
        MultiplechoiceNum,
        MultiplechoiceScore,
        judgeNum,
        judgeScore,
        // fillNum, //填空题数量
        // fillScore,//填空题分值
      } = value;
      if (SinglechoiceNum + MultiplechoiceNum + judgeNum == 0) {
        callback(new Error("请至少选择一项题型题目数量为有效值"));
      } else {
        if (SinglechoiceNum > 0 && SinglechoiceScore == 0) {
          callback(new Error("题目数量大于0的题型分数应为有效值"));
        } else if (MultiplechoiceNum > 0 && MultiplechoiceScore == 0) {
          callback(new Error("题目数量大于0的题型分数应为有效值"));
        } else if (judgeNum > 0 && judgeScore == 0) {
          callback(new Error("题目数量大于0的题型分数应为有效值"));
        }
        // else if (fillNum > 0 && fillScore == 0) {
        //   callback(new Error("题目数量大于0的题型分数应为有效值"));
        // }
        else {
          callback();
        }
      }
    };
    //校验分数
    let passSorceCheck = (rule, value, callback) => {
      let reg = /^[0-9]\d*$/;
      console.log(value);
      if (value && !reg.test(value)) {
        callback(new Error("及格分数应为大于或等于0的整数"));
      } else if (Number(value) > this.ruleForm.totalScore) {
        callback(new Error("及格分数不能大于总分数"));
      } else if (value == undefined) {
        callback(new Error("请输入及格分数"));
      } else {
        callback();
      }
    };
    return {
      active: 0, //steps步数条
      //基本设置
      ruleForm: {
        examName: "", //考试名称
        examCoverUrl: "", //考试封面URL
        examCover: "", //考试封面KEY
        theoryTestTime: "", //考试时间
        theoryTestLocation: "", //考试地点
        volumeGroupMethod: "", //组卷方式
        examNotes: "", //考试注意事项
      },
      rules: {
        examName: [
          {
            required: true,
            message: "请输入考试名称",
            trigger: "change",
          },
        ],
        theoryTestTime: [
          {
            required: true,
            message: "请选择考试时间",
            trigger: "change",
          },
        ],
        theoryTestLocation: [
          {
            required: true,
            message: "请输入考试地点",
            trigger: "blur",
          },
        ],
        volumeGroupMethod: [
          {
            required: true,
            message: "请选择组卷方式",
            trigger: "blur",
          },
        ],
        examNotes: [
          {
            required: true,
            message: "请输入考试注意事项",
            trigger: "blur",
          },
        ],
      },
      examinationId: "",
      examinationStu: "add",
      isPublish: false,
      lookDisabled: true,
      /* 第二部 */
      questionbankList: [],
      questionBankForm: {
        questionBankId: "",
        paperName: "", //组卷名称
        // 随机抽题时各题型的参数
        paperObj: {
          SinglechoiceNum: "0",
          SinglechoiceScore: 0,
          MultiplechoiceNum: "0",
          MultiplechoiceScore: 0,
          judgeNum: "0",
          judgeScore: 0,
          // fillNum: "0",
          // fillScore: 0,
        },
        totalNum: "",
        totalScore: "",
        paperScore: "",
      },
      questionBankFormRules: {
        paperName: [{ required: true, message: "请输入组卷名称" }],
        questionBankId: [{ required: true, message: "请选择指定题库" }],
        totalScore: [{ required: true, message: "请选择试卷或试题计算总分数" }],
        paperScore: [{ required: true, validator: passSorceCheck }],
        paperObj: [
          {
            required: true,
            validator: checkPaperObj,
            trigger: ["blur", "change"],
          },
        ],
      },
      choiceNum: 0, //单选题数量
      multiselectNum: 0, //多选题数量
      judgeNum: 0, //判断题数量
      choiceNumMax: 100, //单选题最大数量
      judgeNumMax: 100, //判断题最大数量
      multiselectNumMax: 100, //多选题最大数量
      paperId: "",
      listData: [],
      // 分页
      fenYe: {
        currentPage: 1, // 当前页数
        size: 10,
        total: null, // 共多少页
      },
      //答案转换
      letterArr: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "X",
        "Y",
        "Z",
      ],
      //选择试卷表单数据
      TestHasForm: {
        paperName: "",
        totalNum: "",
        paperScore: "",
        totalScore: "",
      },
      /* 第三部 */
      //参数数据表单
      configureRuleForm: {
        examLatenessLimit: "", //考试迟到限制
        limitTime: "", //考试迟到时间限制
        isShowScore: "", //是否显示分数
        mainCameraProctoring: "", //pc端主摄像头录像监考
        photoComparisonFrequency: "", //
        desktopProctoring: "", //pc端桌面录屏监考
        subCameraProctoring: "", //手机端副摄像头录像监考
        cutScreenHandInPaper: "", //切屏后强制交卷
        cutScreenFrequency: "", //切屏次数
        cutScreenTime: "", //切屏判定时间
      },
      configureRule: {
        examLatenessLimit: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        limitTime: [
          { required: true, message: "请输入考试迟到时间", trigger: "blur" },
        ],
        isShowScore: [{ required: true, message: "请选择", trigger: "change" }],
        mainCameraProctoring: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        photoComparisonFrequency: [
          { required: true, message: "请输入抓拍对比时间", trigger: "blur" },
        ],
        desktopProctoring: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        subCameraProctoring: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        cutScreenHandInPaper: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        cutScreenFrequency: [
          { required: true, message: "请输入切屏次数", trigger: "change" },
        ],
        cutScreenTime: [
          { required: true, message: "请输入切屏时间", trigger: "change" },
        ],
      },
      datePickerOptions: this.datePickerOption(),
    };
  },
  computed: {},
  mounted() {
    var active = parseInt(sessionStorage.getItem("active")) || 0; // 页面刷新的时候从本地取
    this.active = active;
    this.examinationId = sessionStorage.getItem("examinationId");
    this.paperId = sessionStorage.getItem("paperId");
    this.isPublish = JSON.parse(sessionStorage.getItem("isPublish"));
    this.ruleForm.volumeGroupMethod =
      sessionStorage.getItem("volumeGroupMethod");
    if (this.ruleForm.volumeGroupMethod == "20") {
      if (this.paperId) {
        this.getInfo(this.paperId);
      }
    }
  },
  created() {
    this.active = this.$route.query.active || 0;
    this.examinationId = this.$route.query.examinationId;
    this.paperId = this.$route.query.paperId;
    this.isPublish = this.$route.query.isPublish;
    this.examinationStu = this.$route.query.stu;
    sessionStorage.setItem("isPublish", this.isPublish || false);
    if (this.examinationStu == "edit") {
      sessionStorage.setItem("examinationId", this.examinationId);
      sessionStorage.setItem("paperId", this.paperId);

      this.getInformation(this.examinationId);
      // this.getInfo(this.paperId);
      if (this.ruleForm.volumeGroupMethod == "10") {
        this.getEdit(this.paperId);
        this.lookTest();
      } else if (this.ruleForm.volumeGroupMethod == "20") {
        if (this.paperId) {
          this.getInfo(this.paperId);
        }
      }
      this.getConfigureInfo(this.examinationId);
    }
  },
  methods: {
    datePickerOption() {
      return {
       disabledDate: (time) => {
        let nowDate = new Date();
        let oneDay = 1000 * 60 * 60 * 24;
        let oneYearLater = new Date(nowDate.getTime() + oneDay * 365);
        console.log(nowDate);
        console.log(oneYearLater);
        return time.getTime() < nowDate || time.getTime() > oneYearLater; //注意是||不是&&
      },
      };
    },
    /* 第一步 */
    //考试封面
    handleAvatarSuccess2(res) {
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      let formData = new FormData();
      formData.append("folder ", "COURSE_WARE");
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      const isLt2M = res.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 1MB!");
      } else if (
        ["image/png", "image/gif", "image/jpg", "image/jpeg"].indexOf(
          res.raw.type
        ) === -1
      ) {
        this.$message.error("请上传正确的图片格式");
        return false;
      } else {
        this.$Postformat("/sys/upload", formData)
          .then((result) => {
            this.ruleForm.examCoverUrl = result.data.fileURL;
            this.ruleForm.examCover = result.data.fileKey;
          })
          .catch(() => {
            setTimeout(() => {
              this.$message({
                type: "warning",
                message: "上传失败",
              });
            }, 300);
          });
      }
    },
    // 确认
    doOk() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let url = "/biz/examination/insert";
          let parmar = {
            examName: this.ruleForm.examName, //考试名称
            examCover: this.ruleForm.examCover, //考试封面
            //考试
            theoryTestStart:
              this.ruleForm.theoryTestTime[0].length < 19
                ? this.ruleForm.theoryTestTime[0] + ":00"
                : this.ruleForm.theoryTestTime[0],
            theoryTestEnd:
              this.ruleForm.theoryTestTime[1].length < 19
                ? this.ruleForm.theoryTestTime[1] + ":00"
                : this.ruleForm.theoryTestTime[1],
            theoryTestLocation: this.ruleForm.theoryTestLocation,
            volumeGroupMethod: this.ruleForm.volumeGroupMethod,
            examNotes: this.ruleForm.examNotes.replace(
          /\n/g,
          "sunrealbr"
        ), //考试说明
          };

          if (this.examinationStu == "edit") {
            url = "/biz/examination/modify";
            parmar.examinationId = this.ruleForm.examinationId;
            // parmar.theoryTestStart = this.ruleForm.theoryTestTime[0];
            // parmar.theoryTestEnd = this.ruleForm.theoryTestTime[1];
          }
          this.$post(url, parmar)
            .then((ret) => {
              if (ret.status == 0) {
                if (this.examinationStu == "add") {
                  this.examinationId = ret.data;
                  sessionStorage.setItem("examinationId", this.examinationId);
                }
                this.$message({
                  message: "保存成功",
                  type: "success",
                });
                this.active++;
                sessionStorage.setItem("active", this.active);
                sessionStorage.setItem("paperId", this.paperId);
                if (this.examinationStu == "edit") {
                  if (this.ruleForm.volumeGroupMethod == "10") {
                    if (this.paperId) {
                      this.getEdit(this.paperId);
                      this.lookTest();
                    }
                  } else {
                    if (this.paperId) {
                      this.getInfo(this.paperId);
                    }
                  }
                }
                sessionStorage.setItem(
                  "volumeGroupMethod",
                  this.ruleForm.volumeGroupMethod
                );
              }
            })
            .catch((err) => {
              return;
            });
        }
      });
    },
    // 关闭
    doClose() {
      this.$router.push({
        path: "/web/appraisalInstitution/examinationArrangement",
        query: {
          refresh: true,
        },
      });
    },
    /* 基本信息回显数据 */
    getInformation(examinationId) {
      this.$post("/biz/examination/getInfo", { examinationId })
        .then((ret) => {
          let retData = ret.data;
          this.ruleForm.examinationId = retData.examinationId; //考试名称
          this.ruleForm.examName = retData.examName; //考试名称
          this.ruleForm.examCoverUrl = retData.examCoverUrl; //考试封面URL
          (this.ruleForm.examCover = retData.examCover), //考试封面KEY
            (this.ruleForm.theoryTestTime = [
              retData.theoryTestStart.replaceAll("/", "-"),
              retData.theoryTestEnd.replaceAll("/", "-"),
            ]); //考试时间
          this.ruleForm.theoryTestLocation = retData.theoryTestLocation; //考试地点
          this.ruleForm.examNotes = retData.examNotes.replace(
            /sunrealbr/g,
            "\n"
          ); //考试说明
          this.ruleForm.volumeGroupMethod = retData.volumeGroupMethod; //考试注意事项
        })
        .catch((err) => {
          return;
        });
    },
    /* 第二部 */
    // 指定题库
    getquestionBank(query) {
      if (query) {
        this.questionbankList = [];
        this.$post(
          "/evaluate/question/bank/choiceList",
          {
            questionBankName: query,
          },
          3000,
          true,
          2
        ).then((res) => {
          ;
          this.questionbankList = res.data;
        });
      }
    },
    questionbankChange(val) {
      this.$post(
        "/evaluate/question/bank/type/statistics",
        {
          questionBankId: val,
        },
        3000,
        true,
        2
      ).then((res) => {
        this.choiceNum = res.data.singleChoiceNum; //单选题数量
        this.choiceNumMax = res.data.singleChoiceNum; //单选题选择最大数量
        this.multiselectNum = res.data.multipleChoiceNum; //多选题数量
        this.multiselectNumMax = res.data.multipleChoiceNum; //多选题选择最大数量
        this.judgeNum = res.data.judgeNum; //判断题数量
        this.judgeNumMax = res.data.judgeNum; //判断题选择最大数量
      });
    },
    /* 生产组卷  */
    doTestComposition() {
      this.$refs.questionBankForm.validate((valid) => {
        if (valid) {
          let param = {
            examinationId: this.examinationId,
            paperName: this.questionBankForm.paperName, //组卷名称
            questionBankId: this.questionBankForm.questionBankId, //题库Id
            singleChoiceNum: this.questionBankForm.paperObj.SinglechoiceNum, //单选题选择数量
            singleChoiceScore: this.questionBankForm.paperObj.SinglechoiceScore, //单选题分数
            multipleChoiceNum: this.questionBankForm.paperObj.MultiplechoiceNum, //多选题选择数量
            multipleChoiceScore:
              this.questionBankForm.paperObj.MultiplechoiceScore, //多选题分数
            judgeNum: this.questionBankForm.paperObj.judgeNum, //判断题选择数量
            judgeScore: this.questionBankForm.paperObj.judgeScore, //判断题分数
            fillNum: 0,
            fillScore: 0,
            paperTotalScore: this.questionBankForm.totalScore, //总分
            paperScore: this.questionBankForm.paperScore, //通过分数
          };
          this.$post("/evaluate/paper/bank/select", param).then((ret) => {
            if (ret.status == 0) {
              this.paperId = ret.data;
              sessionStorage.setItem("paperId", this.paperId);
              // this.lookDisabled = false
              this.listData = [];
              this.lookTest();
            }
          });
        }
      });
    },
    getInfo(paperId) {
      console.log(this.examinationId);
      this.$post("evaluate/paper/rule", { paperId })
        .then((ret) => {
          if (this.ruleForm.volumeGroupMethod == "20") {
            this.questionBankForm.paperObj.SinglechoiceNum =
              ret.data.singleChoiceNum; //单选题数量
            this.questionBankForm.paperObj.SinglechoiceScore =
              ret.data.singleChoiceScore; //单选题数量
            this.questionBankForm.paperObj.MultiplechoiceNum =
              ret.data.multipleChoiceNum; //多选题数量
            this.questionBankForm.paperObj.MultiplechoiceScore =
              ret.data.multipleChoiceScore; //多选题数量
            this.questionBankForm.paperObj.judgeNum = ret.data.judgeNum; //判断题数量
            this.questionBankForm.paperObj.judgeScore = ret.data.judgeScore; //判断题数量
            this.getquestionBank(ret.data.questionBankName);
            this.questionBankForm.questionBankId = ret.data.questionBankId;
            this.questionBankForm.paperName = ret.data.paperName;
            this.questionBankForm.paperScore = ret.data.paperScore;
            this.questionbankChange(ret.data.questionBankId);
          } else if (this.ruleForm.volumeGroupMethod == "10") {
            this.TestHasForm = {
              paperName: ret.data.paperName,
              totalNum:
                Number(ret.data.singleChoiceNum) +
                Number(ret.data.multipleChoiceNum) +
                Number(ret.data.judgeNum),
              paperScore: ret.data.paperScore,
              totalScore: ret.data.paperTotalScore,
            };
          }

          this.lookTest();
        })
        .catch((err) => {
          return;
        });
    },
    getEdit(paperId) {
      this.$post("/evaluate/paper/getInfo", {
        paperId,
      }).then((ret) => {
        this.TestHasForm = {
          paperName: ret.data.paperName,
          totalNum: ret.data.questionNum,
          paperScore: ret.data.paperScore,
          totalScore: ret.data.paperTotalScore,
        };
      });
    },
    /* 预览试卷 */
    lookTest(page = 1, pagesize = this.fenYe.size) {
      const params = {
        pageNum: page,
        pageSize: pagesize,
        paperId: this.paperId,
      };
      this.$post("/evaluate/paper/question/pageList", params)
        .then((res) => {
          this.listData = res.data.list;
          console.log(this.listData);
          this.fenYe.total = res.data.total;
          //  this.indexMethod()
        })
        .catch(() => {
          return;
        });
    },
    handleSizeChange(val) {
      this.fenYe.size = val;
      this.fenYe.currentPage = 1;
      this.lookTest(this.fenYe.currentPage, this.fenYe.size);
    },
    // 当前页数
    handleCurrentChange(val) {
      this.fenYe.currentPage = val;
      this.lookTest(this.fenYe.currentPage, this.fenYe.size);
    },
    doActive() {
      this.active++;
      sessionStorage.setItem("active", this.active);
    },
    /* 选择试卷 */
    doTestHas() {
      console.log("55555");
      this.$refs.examPaper.showPopUp();
    },
    /* 回调 */
    examPaperBack(ret) {
      console.log(ret);
      this.TestHasForm = {
        paperName: ret.paperName,
        totalNum: ret.questionNum,
        paperScore: ret.paperScore,
        totalScore: ret.paperTotalScore,
      };
      this.paperId = ret.paperId;
      this.lookTest();
    },
    //选完试卷进行绑定
    doActiveBind() {
      this.$post("/evaluate/paper/examiantion/bind", {
        examinationId: this.examinationId,
        paperId: this.paperId,
      }).then((ret) => {
        if (ret.status == 0) {
          this.$message.success("试卷绑定成功");
          this.active++;
          sessionStorage.setItem("active", this.active);
        }
      });
    },
    /* 第三部 */
    doOkThree() {
      this.$refs.configureRuleForm.validate((valid) => {
        if (valid) {
          let param = {
            examinationId: this.examinationId, //考务Id
            examLatenessLimit: this.configureRuleForm.examLatenessLimit, //考试迟到限制
            isShowScore: this.configureRuleForm.isShowScore, //是否显示分数
            mainCameraProctoring: this.configureRuleForm.mainCameraProctoring, //pc端主摄像头录像监考
            desktopProctoring: this.configureRuleForm.desktopProctoring, //pc端桌面录屏监考
            subCameraProctoring: this.configureRuleForm.subCameraProctoring, //手机端副摄像头录像监考
            cutScreenHandInPaper: this.configureRuleForm.cutScreenHandInPaper, //切屏后强制交卷
          };
          if (this.configureRuleForm.examLatenessLimit) {
            param.limitTime = this.configureRuleForm.limitTime;
          }
          if (this.configureRuleForm.mainCameraProctoring) {
            param.photoComparisonFrequency =
              this.configureRuleForm.photoComparisonFrequency;
          }
          if (this.configureRuleForm.cutScreenHandInPaper) {
            param.cutScreenFrequency =
              this.configureRuleForm.cutScreenFrequency;
            param.cutScreenTime = this.configureRuleForm.cutScreenTime;
          }
          if (this.configureRuleForm.configureId) {
            param.configureId = this.configureRuleForm.configureId;
            param.cutScreenTime = this.configureRuleForm.cutScreenTime;
          }
          this.$post(
            this.configureRuleForm.configureId
              ? "/examination/configure/modify"
              : "/examination/configure/insert",
            param
          ).then((ret) => {
            if (ret.status == 0) {
              this.$message.success("操作成功");
              this.doClose();
            }
          });
        }
      });
    },
    /* /examination/configure/getInfo */
    getConfigureInfo(examinationId) {
      this.$post("/examination/configure/getInfo", { examinationId }).then(
        (ret) => {
          this.configureRuleForm = {
            ...this.configureRuleForm,
            ...ret.data,
          };
        }
      );
    },
  },
  watch: {
    // 监听抽题数量变化 把分数清零
    "questionBankForm.paperObj.SinglechoiceNum": {
      handler: function (val) {
        console.log(val);
        if (val == 0) {
          this.questionBankForm.paperObj.SinglechoiceScore = 0;
        }
        if (val == undefined) {
          this.questionBankForm.paperObj.SinglechoiceNum = "0";
        }
      },
    },
    "questionBankForm.paperObj.MultiplechoiceNum": {
      handler: function (val) {
        if (val == 0) {
          this.questionBankForm.paperObj.MultiplechoiceScore = 0;
        }
        if (val == undefined) {
          this.questionBankForm.paperObj.MultiplechoiceNum = "0";
        }
      },
    },
    "questionBankForm.paperObj.judgeNum": {
      handler: function (val) {
        if (val == 0) {
          this.questionBankForm.paperObj.judgeScore = 0;
        }
        if (val == undefined) {
          this.questionBankForm.paperObj.judgeNum = "0";
        }
      },
    },
    // "questionBankForm.paperObj.fillNum": {
    //   handler: function (val) {
    //     if (val == 0) {
    //       this.questionBankForm.paperObj.fillScore = 0;
    //     }
    //     if (val == undefined) {
    //       this.questionBankForm.paperObj.fillNum = "0";
    //     }
    //   },
    // },
    // 计算总题数 || 总分
    "questionBankForm.paperObj": {
      handler: function (val) {
        let {
          SinglechoiceNum,
          SinglechoiceScore,
          MultiplechoiceNum,
          MultiplechoiceScore,
          judgeNum,
          judgeScore,
          // fillNum,
          // fillScore,
        } = this.questionBankForm.paperObj;
        this.questionBankForm.totalNum =
          SinglechoiceNum + MultiplechoiceNum + judgeNum;
        this.questionBankForm.totalScore =
          SinglechoiceNum * SinglechoiceScore +
          MultiplechoiceNum * MultiplechoiceScore +
          judgeNum * judgeScore;
        this.questionBankForm.paperScore =
          (SinglechoiceNum * SinglechoiceScore +
            MultiplechoiceNum * MultiplechoiceScore +
            judgeNum * judgeScore) *
          (0.6)
            // fillNum * fillScore
            .toFixed(1);
      },
      deep: true,
    },
  },
};
</script>
<style lang="less" scoped>
.framePage-halfBody {
  display: flex;
  flex: 1;
  overflow: hidden;
  background: #f5f6f5;
  .framePage-article {
    background-color: #fff;
    margin-right: 0.675rem;
    height: 100%;
    padding: 1rem 0.675rem;
    box-sizing: border-box;
    width: 25%;
    min-width: 25rem;
    .paperObj /deep/.el-form-item__content {
      margin-left: 0 !important;
    }
  }
}
.tixing {
  font-size: 14px;
  height: 30px;
  padding: 0.25rem;
  border-radius: 0.2rem;
}
.tixing1 {
  color: #37bf77;
  background-color: #d9efe4;
}
.tixing2 {
  color: #fea317;
  background-color: #f9efd2;
}
.tixing3 {
  color: #4574f9;
  background-color: #f1f7ff;
}
.tixing4 {
  color: #f861d8;
  background-color: #fff2fe;
}
.list {
  .list-item {
    background-color: #fafafa;
    padding: 1rem 0;
    margin-bottom: 0.5rem;
    .subject {
      margin-left: 1rem;
      margin-right: 1.5rem;
      .subject-title {
        display: flex;
        justify-content: space-between;
        .number {
          padding-left: 1rem;
        }
        img {
          width: 100px;
        }
      }
      .option {
        display: flex;
        padding: 0.75rem;
        justify-content: space-between;
        span {
          line-height: 2rem;
          width: auto;
          display: flex;
          align-items: center;
          .qbimg {
            width: 5rem;
          }
        }
      }
    }
  }
}
</style>
